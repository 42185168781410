import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { endOfToday, startOfToday, subDays } from 'date-fns';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { map, switchMap } from 'rxjs';
import { FilterService } from '../../services/filter/filter.service';
import { SlideScanStatus } from '../../types/slide-scan-status';
import { IconComponent } from '../icon/icon.component';
import { MultiSelectComponent } from '../multi-select/multi-select.component';
import { SelectComponent } from '../select/select.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { StatusPickerComponent } from './status-picker/status-picker.component';
import { TagOptionsType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { shared } from '@tig-dpqa-cloud/contract-backend-common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserContextService } from '../../services/user-context/user-context.service';

@Component({
  selector: 'dpqa-filter-bar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzDividerModule,
    IconComponent,
    DateRangePickerComponent,
    StatusPickerComponent,
    NzSelectModule,
    SelectComponent,
    MultiSelectComponent,
    FormsModule,
  ],
  templateUrl: './filter-bar.component.html',
  styleUrl: './filter-bar.component.less',
})
export class FilterBarComponent {
  private filterService = inject(FilterService);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private userContextService = inject(UserContextService);

  public customField1DisplayName = computed(
    () => this.userContextService.tenantConfig()?.customFields.customField1?.displayName,
  );

  public numberOfSlides = input(0);

  public selectedDateRange = this.filterService.selectedDateRange;
  public selectedStatus = this.filterService.status;
  public selectedStaining = this.filterService.staining;
  public selectedScannerType = this.filterService.selectedScannerType;
  public selectedRack = this.filterService.selectedRack;
  public selectedTag = this.filterService.selectedTag;
  public selectedCustomField1 = this.filterService.customField1;

  public scannerTypeOptions = toSignal(
    this.filterService.filterInfo$.pipe(
      map(({ scanners }) =>
        Object.keys(scanners)
          // Filter out 'unknown' scanner types for now
          .filter((scannerType) => scannerType !== 'unknown')
          .map((scannerType) => ({
            // key: scannerType === 'unknown' ? this.translateService.instant(`COMMON.UNKNOWN`) : scannerType,
            key: scannerType,
            value: scannerType,
          }))
          .sort((a, b) => a.key.localeCompare(b.key)),
      ),
    ),
  );

  public rackOptions = toSignal(
    toObservable(this.selectedScannerType).pipe(
      switchMap((scannerType) =>
        this.filterService.filterInfo$.pipe(
          map(({ scanners }) => {
            if (scannerType) {
              return scanners[scannerType];
            }
            return [];
          }),
          map((rackOptions) =>
            rackOptions
              // Filter out 'unknown' rack numbers for now
              .filter((rack) => rack !== 'unknown')
              .map((rack) => ({
                // key: rack === 'unknown' ? this.translateService.instant(`COMMON.UNKNOWN`) : rack.toString(),
                key: rack.toString(),
                value: rack,
              }))
              .sort((a, b) => a.key.localeCompare(b.key)),
          ),
        ),
      ),
    ),
  );

  public stainOptions = toSignal(
    this.filterService.filterInfo$.pipe(
      map(({ staining }) =>
        staining.map((stain) => ({
          key: stain,
          value: stain,
        })),
      ),
      map((stainOptions) => [
        ...stainOptions,
        { key: this.translateService.instant('COMMON.NOT_AVAILABLE') as string, value: 'unknown' },
      ]),
    ),
    { initialValue: [] },
  );

  public customField1Options = toSignal(
    this.filterService.filterInfo$.pipe(
      map(({ customField1 }) =>
        customField1.map((c) => ({
          key: c,
          value: c,
        })),
      ),
    ),
    { initialValue: [] },
  );

  public tagOptionsInQueue: { key: string; value: TagOptionsType; group?: string }[] = [
    { key: this.translateService.instant('FILTER_BAR.WITHOUT_ISSUES'), value: 'WITHOUT_ISSUES' },
    { key: this.translateService.instant('FILTER_BAR.PROCESSING_FAILED'), value: 'PROCESSING_FAILED' },
    { key: this.translateService.instant('FILTER_BAR.RESCAN'), value: 'RESCAN' },
    {
      key: this.translateService.instant('COMMON.ISSUES.OUT_OF_FOCUS_REGION'),
      value: 'OUT_OF_FOCUS_REGION',
      group: 'FILTER_BAR.TAGS.WITH_ISSUES',
    },
    {
      key: this.translateService.instant('COMMON.ISSUES.TISSUE_NOT_DETECTED'),
      value: 'TISSUE_NOT_DETECTED',
      group: 'FILTER_BAR.TAGS.WITH_ISSUES',
    },
    {
      key: this.translateService.instant('COMMON.ISSUES.FOLDS'),
      value: 'FOLDS',
      group: 'FILTER_BAR.TAGS.WITH_ISSUES',
    },
  ];

  public tagOptionsVerified: { key: string; value: TagOptionsType; group?: string }[] = [
    { key: this.translateService.instant('FILTER_BAR.WITHOUT_ISSUES'), value: 'WITHOUT_ISSUES' },
    { key: this.translateService.instant('COMMON.REJECTED_ISSUES'), value: 'REJECTED_ISSUES' },
    { key: this.translateService.instant('COMMON.ACCEPTED_ISSUES'), value: 'ACCEPTED_ISSUES' },
    { key: this.translateService.instant('FILTER_BAR.USER_DETECTED_ISSUES'), value: 'USER_DETECTED_ISSUES' },
    { key: this.translateService.instant('FILTER_BAR.PROCESSING_FAILED'), value: 'PROCESSING_FAILED' },
    { key: this.translateService.instant('FILTER_BAR.RESCAN'), value: 'RESCAN' },
    { key: this.translateService.instant('FILTER_BAR.TO_BE_RESCANNED'), value: 'TO_BE_RESCANNED' },
    ...shared.SlideScanIssue.options.map((issue) => ({
      key: this.translateService.instant(`COMMON.ISSUES.${issue}`),
      value: issue,
      group: 'FILTER_BAR.TAGS.WITH_ISSUES',
    })),
  ];

  private today = startOfToday();

  constructor() {
    effect(() => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.filterService.queryParamFilter(),
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    });
  }

  public disabledDates = (current: Date): boolean => {
    return current < subDays(this.today, 30) || current > endOfToday();
  };

  public presetRanges = {
    [this.translateService.instant('COMMON.DATE_RANGE_PRESET.TODAY')]: [this.today, this.today],
    [this.translateService.instant('COMMON.DATE_RANGE_PRESET.LAST_7_DAYS')]: [subDays(this.today, 6), this.today],
    [this.translateService.instant('COMMON.DATE_RANGE_PRESET.LAST_31_DAYS')]: [subDays(this.today, 30), this.today],
  };

  public selectedDateRangeChanged(range: Date[]) {
    this.filterService.updateSelectedDateRange([range[0], range[1]]);
  }

  public updateStatus(status: SlideScanStatus) {
    this.filterService.updateStatus(status);
  }

  public handleUpdateSelectedScannerType(scannerType: string) {
    this.filterService.updateSelectedScannerType(scannerType);
    this.filterService.updateSelectedRack(undefined);
  }

  public handleUpdateSelectedRack(rack: (number | 'unknown')[]) {
    this.filterService.updateSelectedRack(rack);
  }

  public handleUpdateSelectedTag(tag: string[]) {
    this.filterService.updateSelectedTag(tag);
  }

  public handleUpdateStaining(staining: string[]) {
    this.filterService.updateStaining(staining);
  }

  public handleUpdateCustomField1(customField1: string[]) {
    this.filterService.updateCustomField1(customField1);
  }
}
