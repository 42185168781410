import { createInjectable } from 'ngxtension/create-injectable';
import { environment } from '../../../../environments/environment';
import { inject } from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';

type DiscoveryDocument = {
  issuer: string;
  authorization_endpoint: string;
  token_endpoint: string;
  end_session_endpoint: string;
  jwks_uri: string;
};

export const OAuthDiscoveryService = createInjectable(() => {
  const http = inject(HttpClient);
  const discoveryUri = environment.oauth_discovery_uri;

  const discoveryDocument$ = http.get<DiscoveryDocument>(discoveryUri).pipe(shareReplay(1));

  return {
    getAuthorizationUri: () =>
      discoveryDocument$.pipe(map((discoveryDocument) => discoveryDocument.authorization_endpoint)),
    getLogoutUri: () => discoveryDocument$.pipe(map((discoveryDocument) => discoveryDocument.end_session_endpoint)),
  };
});
