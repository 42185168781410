import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, input, model, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzDatePickerModule, NzRangePickerComponent } from 'ng-zorro-antd/date-picker';
import { DEFAULT_DATE_FORMAT, getDateFormatFromLocale } from '../../../util/date-format';

@Component({
  selector: 'dpqa-date-range-picker',
  standalone: true,
  imports: [CommonModule, NzDatePickerModule, FormsModule],
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.less',
})
export class DateRangePickerComponent {
  private translateService = inject(TranslateService);
  private currentLocale = this.translateService.getBrowserCultureLang();

  public dateFormat = this.currentLocale ? getDateFormatFromLocale(this.currentLocale) : DEFAULT_DATE_FORMAT;

  private dateRangePicker = viewChild.required(NzRangePickerComponent, { read: ElementRef });

  selectedDateRange = model.required<Date[]>();
  disabledDates = input<(d: Date) => boolean>();
  presetRanges = input<Record<string, Date[]>>();

  handleOpenChange(open: boolean) {
    if (open) {
      /**
       * In order to focus on the available dates, we need to navigate to the previous month
       * if the selected range is within the current month. The timeout is needed to wait for the
       * DOM to be updated.
       */
      setTimeout(() => {
        if (this.selectedDateRange()[0].getMonth() === new Date().getMonth()) {
          document.querySelector<HTMLElement>('.ant-picker-header-prev-btn')?.click();
        }
      }, 0);

      /**
       * We always want to focus the first date input so that the user always needs
       * to fill in a full range, also when clicking the second input.
       */
      const input = this.dateRangePicker().nativeElement.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  }
}
