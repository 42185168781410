import { z } from 'zod';
import qs from 'qs';

import { FilterSchema, type FilterType, filter as applyFilter, toWhereClause } from './filtering';
import { PagingSchema, page as applyPaging, calculateLimitAndOffset } from './paging';
import { type SortEntry, SortSchema, sort as applySorting, toOrderByClause } from './sorting';
import { type Column, type SQL } from 'drizzle-orm';
import { type PgSelectQueryBuilder } from 'drizzle-orm/pg-core';

export const SFPSchema = SortSchema.merge(PagingSchema).merge(FilterSchema);

export type SFPInputType = z.input<typeof SortSchema> & z.input<typeof PagingSchema> & FilterType;
export type SFPType = z.output<typeof SortSchema> & z.output<typeof PagingSchema> & FilterType;

/*
 * When the option with a generate SQL statement is used, you have to use sql``.
 * This is compatible with the current solution.
 * Using QueryBuilder has nog given any success yet, so would avoid this for now
 */
export type DBColDefinition = Column | { col: Column; sql: (arg: any) => SQL };

// Other types to export
export { PagedResult, getPagedResultSchema } from './paging';
export { FilterType, toWhereClause } from './filtering';

/**
 * Deserializes the query string into the SFP (sorting, filtering, paging) object.
 * @param queryString The querystring to parse
 * @returns an SFP object
 */
export const deserialize = (queryString: string): SFPType => {
  return SFPSchema.parse(qs.parse(queryString)) as SFPType;
};

export const getQuerySchema = <T extends z.AnyZodObject>(schema: T) =>
  z
    .object({
      page: z.string().describe('The page you want to be on'),
      pagesize: z.string().describe('The pagesize for the request'),
      sort: z.array(z.string()).describe('The fields you want sort on, these are the same as the queryable fields'),
    })
    .merge(schema);

/**
 * Serializes the SFP (sorting, filtering, paging) object into the query string.
 * @param sfp The SFP object
 * @returns An HTML-safe query string.
 */
export const serialize = (sfp: SFPType) => {
  // TODO Because of the different types of the schema, we might need to add a second zod validation for this schema to be used for validation here.
  return qs.stringify({
    ...sfp,
    // This is handled by the schema in the deserializer. Maybe we can use a schema for the other way round as well? see above TODO
    sort: sfp['sort']?.map(({ direction, param }: SortEntry) => `${param}:${direction}`),
  });
};

const omitKeys = <T>(obj: Record<string, T>, toOmit: string[]) =>
  Object.entries(obj).reduce((acc, [key, value]) => (toOmit.includes(key) ? acc : { ...acc, [key]: value }), {});

const getSortFilterAndPagingFields = (sfp: SFPType) => ({
  sorting: { sort: sfp.sort || [] },
  paging: { page: sfp.page, pagesize: sfp.pagesize },
  filter: omitKeys(sfp, ['sort', 'page', 'pagesize']) as FilterType,
});

export const applyAll =
  (sfp: SFPType, dbMap: Record<string, DBColDefinition>) =>
    <T extends PgSelectQueryBuilder>(qb: T): T => {
      const { sorting, paging, filter } = getSortFilterAndPagingFields(sfp);

      return applyPaging(paging)(applySorting(sorting, dbMap)(applyFilter(filter, dbMap)(qb)));
    };

export const getSFPFields = (sfp: SFPType, dbMap: { [param: string]: DBColDefinition }) => {
  const { sorting, paging, filter } = getSortFilterAndPagingFields(sfp);

  return {
    ...calculateLimitAndOffset(paging),
    orderBy: toOrderByClause(dbMap)(sorting),
    where: toWhereClause(dbMap)(filter),
  };
};

// TODO create factory for use in web app.
