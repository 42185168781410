<nav>
  <ul>
    @for (route of routes; track route.name) {
      <li [routerLink]="route.link" queryParamsHandling="replace" routerLinkActive="active" *dpqaRequirePermissions="route.requiredPermissions">
        <dpqa-icon [iconName]="route.icon" />
        <p>{{ route.name | translate }}</p>
      </li>
    }
  </ul>

  <dpqa-profile />
</nav>
