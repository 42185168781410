<ng-select
  dropdownPosition="bottom"
  [items]="options()"
  [multiple]="true"
  bindLabel="key"
  bindValue="value"
  groupBy="group"
  [selectableGroup]="true"
  [selectableGroupAsModel]="false"
  [closeOnSelect]="false"
  [ngModel]="value()"
  (ngModelChange)="handleValueChange($event)"
  [placeholder]="placeholder()"
  [searchable]="searchable()"
  [disabled]="disabled()"
>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    @if (items.length === maxTagCount()) {
      @for (item of items; track item.key) {
        <div class="ng-value">
          <span class="ng-value-label"> {{ item.key }}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
      }
    }
    @if (items.length > maxTagCount()) {
      <div class="ng-value">
        <span class="ng-value-label">{{ items.length }} selected items</span>
      </div>
    }
  </ng-template>
  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> {{ item.group | translate }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> {{ item.key }}
  </ng-template>
</ng-select>
