<label
  nz-checkbox
  class="checkbox"
  [ngStyle]="{
    gap: gap(),
    'font-size': fontSize(),
    'font-weight': fontWeight(),
    'line-height': lineHeight()
  }"
  [nzChecked]="checked()"
  [nzIndeterminate]="indeterminate()"
  (nzCheckedChange)="checkedChanged.emit($event)"
  ><ng-content
/></label>
