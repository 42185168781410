import { type PgSelectQueryBuilder } from 'drizzle-orm/pg-core';
import { z } from 'zod';
import { shared } from '@tig-dpqa-cloud/contract-backend-common';

// Type to be used as response to any paged request.
export const getPagedResultSchema = <T extends z.ZodTypeAny>(schema: T) =>
  z.object({
    list: z.array(schema).default([]),
    totalResults: z.number().default(0),
  });

export type PagedResult<T> = {
  list: T[];
  totalResults: number;
};

export const PagingSchema = z.object({
  page: z.string().min(1).default('1').pipe(z.coerce.number().int().positive()),
  pagesize: z
    .string()
    .min(1)
    .default(`${shared.CONSTANTS.DEFAULT_PAGE_SIZE}`)
    .pipe(z.coerce.number().int().positive().max(shared.CONSTANTS.MAX_PAGE_SIZE)),
});

export type PagingInputType = z.input<typeof PagingSchema>;
export type PagingType = z.output<typeof PagingSchema>;

export const calculateLimitAndOffset = (paging: PagingType) => ({
  limit: paging.pagesize,
  offset: (paging.page - 1) * paging.pagesize,
});

export const page =
  (paging: PagingType) =>
    <T extends PgSelectQueryBuilder>(qb: T): T => {
      const { limit, offset } = calculateLimitAndOffset(paging);
      return qb.limit(limit).offset(offset);
    };
