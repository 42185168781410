import { Column, asc, desc } from 'drizzle-orm';
import { type PgSelectQueryBuilder } from 'drizzle-orm/pg-core';
import { BadRequest } from 'http-errors';
import { type DBColDefinition } from '.';
import { type RefinementCtx, z } from 'zod';

const sortDirections = z.union([z.literal('asc'), z.literal('desc')]).default('asc');

export type SortDirections = z.infer<typeof sortDirections>;

export type SortEntry = {
  param: string;
  direction: SortDirections;
};

export const SortSchema = z.object({
  sort: z
    .string()
    .min(1)
    .transform((input, ctx: RefinementCtx): SortEntry => {
      const [param, dir] = input.split(':');
      const { success, error, data: direction } = sortDirections.safeParse(dir);
      if (!success) {
        error.issues.forEach(ctx.addIssue);
        return z.NEVER;
      }
      return {
        param,
        direction,
      };
    })
    .array()
    .optional(),
});

export type SortInputType = z.input<typeof SortSchema>;
export type SortType = z.output<typeof SortSchema>;

const helper: Record<SortDirections, typeof asc | typeof desc> = {
  asc,
  desc,
};

export const toOrderByClause =
  (dbMap: { [param: string]: DBColDefinition }) =>
    ({ sort: sortDef }: SortType) => {
      if (!sortDef) {
        return [];
      }
      return sortDef.map(({ param, direction }) => {
        if (!dbMap[param] || !(dbMap[param] instanceof Column)) {
          throw new BadRequest(`Unknown sorting parameter ${param}`);
        }
        return helper[direction](dbMap[param] as Column);
      });
    };

export const sort =
  ({ sort: sortDef }: SortType, dbMap: { [param: string]: DBColDefinition }) =>
    <T extends PgSelectQueryBuilder>(qb: T): T => {
      if (!sortDef || sortDef.length === 0) { return qb; }
      return qb.orderBy(...toOrderByClause(dbMap)({ sort: sortDef }));
    };
