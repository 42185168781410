import { z } from 'zod';
import {
  and,
  or,
  gte,
  lte,
  gt,
  lt,
  ilike,
  notIlike,
  inArray,
  notInArray,
  eq,
  ne,
  not,
  type SQL,
  type SQLWrapper,
  sql,
  isNull,
} from 'drizzle-orm';
import httpErrors from 'http-errors';
import { type PgSelectQueryBuilder } from 'drizzle-orm/pg-core';
import { type DBColDefinition } from '.';

// Helper for like & notLike functions
const ensureSQLWildcards = (input?: string): string | undefined => {
  if (!input) { return; }
  if (input.includes('%')) { return input; }
  return `%${input}%`;
};

const BaseFilterSchema = z.object({
  // Type depends on the parameter on which to sort, so it is unknown here how to parse.
  // When necessary, during sql creation parsing will be done based on the database column type.
  eq: z.string().optional(),
  ne: z.string().optional(),
  gt: z.string().optional(),
  gte: z.string().optional(),
  lt: z.string().optional(),
  lte: z.string().optional(),
  in: z.string().array().optional(),
  notIn: z.string().array().optional(),
  like: z.string().optional().transform(ensureSQLWildcards),
  notLike: z.string().optional().transform(ensureSQLWildcards),
  isNull: z.string().optional(),
});

type FilterValueType = z.output<typeof BaseFilterSchema> & {
  and?: FilterValueType;
  or?: FilterValueType;
  not?: FilterValueType;
};

// TODO Can we enforce that only one key is present unless if the parent key is and/or.
const FilterValueSchema: z.ZodType<FilterValueType> = BaseFilterSchema.extend({
  and: z.lazy(() => FilterValueSchema.refine((filter) => Object.keys(filter).length > 1)).optional(),
  or: z.lazy(() => FilterValueSchema.refine((filter) => Object.keys(filter).length > 1)).optional(),
  not: z.lazy(() => FilterValueSchema.refine((filter) => Object.keys(filter).length === 1)).optional(),
}).strict();

export type FilterType = {
  and?: FilterType | Record<string, z.infer<typeof FilterValueSchema>>;
  or?: FilterType | Record<string, z.infer<typeof FilterValueSchema>>;
};

export const FilterSchema: z.ZodObject<any, any, any, FilterType, FilterType> = z
  .object({
    and: z.lazy(() => z.union([FilterSchema, z.record(FilterValueSchema)])).optional(),
    or: z.lazy(() => z.union([FilterSchema, z.record(FilterValueSchema)])).optional(),
  })
  .strict();

const valueFilters = {
  eq,
  ne,
  gt,
  gte,
  lt,
  lte,
  in: inArray,
  notIn: notInArray,
  like: ilike,
  notLike: notIlike,
  isNull,
} as const;

type CombinatorsType = (...conditions: Array<SQLWrapper | undefined>) => SQL<unknown> | undefined;

const combinators: Record<'and' | 'or' | 'not', CombinatorsType> = {
  and,
  or,
  not: (...conditions) => {
    if (conditions.length !== 1 || !conditions[0]) { throw new httpErrors.InternalServerError('This state should not happen.'); }
    return not(conditions[0]);
  },
};
const assertcheckIfKeyOfValueFilters = (key: string): key is keyof typeof valueFilters => key in valueFilters;

const assertIfKeyOfCombinators = (key: string): key is keyof typeof combinators => key in combinators;

const dataConverters: Record<string, (input: string | string[]) => any> = {
  PgTimestamp: (input) => {
    if (Array.isArray(input)) { return input.map((d) => new Date(d)); }
    return new Date(input);
  },
};

const filterDefinitionToSql = (dbCol: DBColDefinition) => {
  const rec = (def: FilterValueType): SQL | undefined => {
    const conditions = Object.entries(def)
      .map(([fn, value]): SQL | undefined => {
        if (assertcheckIfKeyOfValueFilters(fn)) {
          const [_, parseString] = Object.entries(dataConverters).find(([key]) =>
            'col' in dbCol ? key === dbCol.col.columnType : key === dbCol.columnType,
          ) ?? [undefined, (v) => v];
          if ('col' in dbCol) {
            return (valueFilters[fn] as any)(dbCol.col, sql`(${dbCol.sql(parseString(value as string | string[]))})`);
          }

          if (fn === 'isNull') {
            return valueFilters[fn](dbCol);
          }

          return (valueFilters[fn] as any)(dbCol, parseString(value as string | string[]));
        }
        if (assertIfKeyOfCombinators(fn)) {
          return combinators[fn](
            ...Object.entries(value as FilterValueType)
              .map(([key, value]) => ({ [key]: value }) as FilterValueType)
              .map(rec),
          );
        }
        throw new httpErrors.BadRequest('Invalid filter definition');
      })
      .filter((x) => x);
    return and(...conditions); // implicit and of filters for a single parameter
  };
  return rec;
};

export const toWhereClause =
  (dbMap: { [param: string]: DBColDefinition }) =>
    (filter: FilterType): SQL<unknown> | undefined => {
      if (Object.entries(filter).length === 0 || !filter) {
        return undefined;
      }

      const booleanCombinators: Record<string, typeof and> = { and, or };

      const rec = (filter: FilterType | Record<string, FilterValueType>): SQL<unknown>[] => {
        if (Object.keys(filter).filter((key) => !Object.keys(booleanCombinators).includes(key)).length > 0) {
          return Object.entries(filter)
            .map(([param, def]) => {
              if (!dbMap[param]) {
                throw new httpErrors.BadRequest(`Unknown filter parameter ${param}`);
              }
              return filterDefinitionToSql(dbMap[param])(def);
            })
            .filter((x) => x) as SQL<unknown>[];
        }

        return Object.entries(filter)
          .map(([key, value]) => booleanCombinators[key](...rec(value)))
          .filter((x) => x) as SQL<unknown>[];
      };

      // Only 1 top level operator is allowed, if multiple specified, and takes priority
      const { toFilter, operator } = filter.and
        ? { toFilter: filter.and, operator: and }
        : { toFilter: filter.or, operator: or };

      if (!toFilter) {
        throw new httpErrors.BadRequest();
      }

      return operator(...rec(toFilter));
    };

export const filter =
  (filter: FilterType, dbMap: { [param: string]: DBColDefinition }) =>
    <T extends PgSelectQueryBuilder>(qb: T): T => {
      if (Object.keys(filter).length === 0) { return qb; }

      const whereClause = toWhereClause(dbMap)(filter);
      if (!whereClause) { throw new httpErrors.InternalServerError('No filters were parsed from definition'); }

      return qb.where(whereClause);
    };
