import { Component, booleanAttribute, computed, input, numberAttribute } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { IconComponent } from '../icon/icon.component';
import { ButtonStyle } from './button.model';

@Component({
  selector: 'dpqa-button',
  standalone: true,
  imports: [CommonModule, NzButtonModule, IconComponent],
  templateUrl: './button.component.html',
  styleUrl: './button.component.less',
})
export class ButtonComponent {
  type = input<ButtonStyle>('primary');
  height = input<number>(4);
  padding = input<[number, number, number, number]>([1.4, 1.4, 1.4, 0.8]);
  fontSize = input<number>(1.6);
  iconName = input<string | undefined>();
  iconSize = input(2, { transform: numberAttribute });
  disabled = input(false, { transform: booleanAttribute });
  loading = input(false);

  danger = input(false, { transform: booleanAttribute });

  paddingResult = computed(() =>
    this.padding()
      .map((elem) => `${elem}rem`)
      .join(' '),
  );
}
