import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractCommunicationService } from './abstract-communication.service';

@Injectable({ providedIn: 'root' })
export class HttpCommunicationService implements AbstractCommunicationService {
  private http = inject(HttpClient);

  get<T>(endpoint: `/${string}`, args?: Parameters<typeof this.http.get>[1]): Observable<T> {
    const url = `${endpoint}`;
    return this.http.get<T>(url, args);
  }

  post<T>(body: unknown, endpoint: `/${string}`): Observable<T> {
    const url = `${endpoint}`;
    return this.http.post<T>(url, body);
  }

  put<T>(body: unknown, endpoint: `/${string}`): Observable<T> {
    const url = `${endpoint}`;
    return this.http.put<T>(url, body);
  }

  patch<T>(body: unknown, endpoint: `/${string}`): Observable<T> {
    const url = `${endpoint}`;
    return this.http.patch<T>(url, body);
  }

  delete(id: unknown, endpoint: `/${string}`): Observable<void> {
    const url = `${endpoint}`;
    return this.http.delete<void>(`${url}/${id}`);
  }

  head<T>(endpoint: `/${string}`, args?: Parameters<typeof this.http.head>[1]): Observable<T> {
    const url = `${endpoint}`;
    return this.http.head<T>(url, args);
  }
}
