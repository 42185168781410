import { Component } from '@angular/core';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UserConfigType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { RequirePermissionsDirective } from '../../directives/require-permissions/require-permissions.directive';
import { IconComponent } from '../icon/icon.component';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'dpqa-navigation',
  standalone: true,
  imports: [
    RouterLinkWithHref,
    RouterLinkActive,
    IconComponent,
    TranslateModule,
    ProfileComponent,
    RequirePermissionsDirective,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.less',
})
export class NavigationComponent {
  protected routes: {
    name: string;
    icon: string;
    link: string;
    requiredPermissions: UserConfigType['permissions'];
  }[] = [
      {
        name: 'NAVIGATION.DASHBOARD',
        icon: 'grid',
        link: '/dashboard',
        requiredPermissions: ['DASHBOARD_READ'],
      },
      {
        name: 'COMMON.SLIDES',
        icon: 'slides',
        link: '/slides',
        requiredPermissions: ['SLIDE_SCANS_READ'],
      },
      {
        name: 'NAVIGATION.SETUP',
        icon: 'settings-2',
        link: '/setup',
        requiredPermissions: ['SETUP_WRITE'],
      },
      {
        name: 'NAVIGATION.USER_MANAGEMENT',
        icon: 'users',
        link: '/user-management',
        requiredPermissions: ['USER_MANAGEMENT_WRITE'],
      },
    ];
}
