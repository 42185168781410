import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'dpqa-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less'],
  imports: [CommonModule],
  standalone: true,
})
export class LoadingComponent {
  public number = input(5);
  public height = input(1);
  public animationDelays = computed<Array<string>>(() =>
    Array(this.number())
      .fill('0s')
      .map((_, i) => `${(i * 0.2).toFixed(2)}s`),
  );
}
