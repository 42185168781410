import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { UserContextService } from '../../services/user-context/user-context.service';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ButtonComponent } from '../button/button.component';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'dpqa-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.less',
  imports: [CommonModule, IconComponent, ButtonComponent, NzDividerModule, TranslateModule, NzPopoverModule],
})
export class ProfileComponent {
  private storageService = inject(StorageService);
  private authService = inject(AuthService);
  private userContextService = inject(UserContextService);
  private tenantConfig = this.userContextService.tenantConfig;

  public tenantId = computed(() => this.tenantConfig()?.tenantId || '');
  public tenantDisplayName = computed(() => this.tenantConfig()?.displayName || '');
  public possibleTenants = this.userContextService.possibleTenants;

  public indexCurrentTenant = computed(() =>
    this.userContextService.possibleTenants().findIndex((tenant) => tenant.id === this.tenantConfig()?.tenantId),
  );

  public getColor = (index: number) => ['blue', 'red', 'green'][index % 3];
  public getImage = (index: number) => `assets/images/hospital/hospital.svg#hospital${(index % 3) + 1}-small`;

  public user = this.userContextService.userProfile;

  public dataplaneVersion = computed(() =>
    this.userContextService
      .dataplaneVersion()
      ?.filter((v) => v.version)
      ?.toSorted((a, b) => new Date(a.lastPingedAt || '').getDate() - new Date(b.lastPingedAt || '').getDate())[0]
      ?.version?.slice(-7),
  );

  public cloudVersion = environment.version.slice(-7);

  public handleLogout(): void {
    this.authService.logout();
  }

  public handleTenantChange(tenantId: string): void {
    location.reload();
    this.storageService.set('current_tenant', tenantId);
  }

  public copy(toCopy: 'cloud' | 'dataplane') {
    navigator.clipboard.writeText(toCopy === 'cloud' ? this.cloudVersion : this.dataplaneVersion() || '');
  }
}
