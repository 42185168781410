import { Component, computed, input, numberAttribute, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

@Component({
  selector: 'dpqa-checkbox',
  standalone: true,
  imports: [CommonModule, NzCheckboxModule],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.less',
})
export class CheckboxComponent {
  checked = input<boolean>(false);
  indeterminate = input<boolean>(false);

  fontSizeInput = input(1.6, { transform: numberAttribute, alias: 'fontSize' });
  fontSize = computed(() => `${this.fontSizeInput()}rem`);
  fontWeight = input(600, { transform: numberAttribute });

  lineHeightInput = input(1.6, {
    alias: 'lineHeight',
    transform: numberAttribute,
  });

  lineHeight = computed(() => `${this.lineHeightInput()}rem`);

  gapInput = input(0.8, { alias: 'gap', transform: numberAttribute });
  gap = computed(() => `${this.gapInput()}rem`);

  checkboxSize = input(1.6, { transform: numberAttribute });

  checkedChanged = output<boolean>();
}
