import { z } from 'zod';

import { BucketCredentials, SlideScanItem } from '../shared';

const _ThumbnailResponse = SlideScanItem.pick({ patches: true });

export type ThumbnailResponseType = z.infer<typeof _ThumbnailResponse>['patches'];

export const SlideScansGetResponse = z.object({
  data: SlideScanItem,
  // TODO: Tests are failing if credentials is non optional.
  credentials: BucketCredentials.optional(),
});

export type SlideScansGetResponseType = z.infer<typeof SlideScansGetResponse> | null;
