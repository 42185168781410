<ng-select
  [items]="options()"
  [ngModel]="value()"
  (ngModelChange)="handleValueChange($event)"
  bindLabel="key"
  bindValue="value"
  [placeholder]="placeholder()"
  [clearable]="allowClear()"
  class="dpqa-select"
  searchable="false"
>
</ng-select>
