export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

/**
 * Get the date format string from the current locale to use as `date-fns` config.
 * Implemented as a workaround for {@link https://github.com/NG-ZORRO/ng-zorro-antd/issues/3491}
 */
export const getDateFormatFromLocale = (locale: string) => {
  // Use Intl.DateTimeFormat to get the format parts for the specified locale
  const dtf = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
  const formatParts = dtf.formatToParts(new Date());

  let formatString = '';

  // Map the parts to date-fns format tokens
  formatParts.forEach((part) => {
    switch (part.type) {
      case 'day':
        return (formatString += 'dd');
      case 'month':
        return (formatString += 'MM');
      case 'year':
        return (formatString += 'yyyy');
      default:
        return (formatString += part.value);
    }
  });

  return formatString;
};
