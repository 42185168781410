import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgSelectModule } from '@ng-select/ng-select';

type SelectOptions<T> = {
  key: string;
  value: T;
}[];

@Component({
  selector: 'dpqa-select',
  standalone: true,
  imports: [CommonModule, FormsModule, NzSelectModule, TranslateModule, NgSelectModule],
  templateUrl: './select.component.html',
  styleUrl: './select.component.less',
})
export class SelectComponent<T> {
  public value = input<NoInfer<T>>();
  public options = input<SelectOptions<T>>();
  public selectId = input.required<string>();
  public placeholder = input<string>('');
  public allowClear = input<boolean>(true);

  public updateValue = output<T>();

  handleValueChange(e: T) {
    this.updateValue.emit(e);
  }
}
