<span
  nz-icon
  [ngStyle]="{
    width: iconSize() + 'rem',
    height: iconSize() + 'rem'
  }"
>
  <svg>
    <use [attr.href]="iconLink()" />
  </svg>
</span>
