import { z } from 'zod';

export const tokenRequestBodySchema = z
  .object({
    code: z.string().min(1),
    code_verifier: z.string().min(1),
    refresh_token: z.string().min(1),
  })
  .partial();

export type TokenRequestBodyType = z.infer<typeof tokenRequestBodySchema>;

export const tokenResponseBodySchema = z.object({
  token: z.string().min(1),
  refreshToken: z.string().min(1).optional(),
});

export type TokenResponseType = z.infer<typeof tokenResponseBodySchema>;
